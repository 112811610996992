import { render, staticRenderFns } from "./detail.vue?vue&type=template&id=f6cc406a&scoped=true&"
import script from "./detail.vue?vue&type=script&lang=js&"
export * from "./detail.vue?vue&type=script&lang=js&"
import style0 from "./detail.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./detail.vue?vue&type=style&index=1&id=f6cc406a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6cc406a",
  null
  
)

export default component.exports